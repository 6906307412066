/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import 'typeface-oswald'

import { AdvertisementPopup } from '../AdvertisementPopup'
import { Footer } from '../Footer'

import '../../scss/base.scss'
import * as classes from './layout.module.scss'

import { NavBar } from '../NavBar'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export const Layout = ({ children, className }: Props) => (
  <>
    <NavBar />

    <main className={classNames(classes.main, className)}>{children}</main>

    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
