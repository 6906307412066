import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { FirstTeamQuery } from '../../types/graphql'

import { People } from '../People'

interface Props {
  filter?: string
}

export const FirstTeam = ({ filter }: Props) => {
  const { allWordpressWpGiocatore } = useStaticQuery<FirstTeamQuery>(graphql`
    fragment PersonImage on File {
      childImageSharp {
        fluid(maxWidth: 1080, maxHeight: 1080, quality: 100, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    fragment Person on wordpress__wp_giocatore {
      id
      info {
        Ruolo
        Ordinamento
      }
      featuredMedia: featured_media {
        alt_text
        localFile {
          ...PersonImage
        }
      }
      slug
      title
    }

    query FirstTeam {
      allWordpressWpGiocatore(
        filter: { squadra: { eq: 104 } }
        sort: { fields: [info___Numero], order: ASC }
      ) {
        nodes {
          ...Person
          featuredMediaHover: featured_media_hover {
            localFile {
              ...PersonImage
            }
          }
        }
      }
    }
  `)

  return (
    <People
      filter={filter}
      nodes={allWordpressWpGiocatore.nodes?.sort(
        (a, b) => a.info.Ordinamento - b.info.Ordinamento
      )}
      urlPrefix={'/prima-squadra'}
    />
  )
}
