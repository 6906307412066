import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'

import { SponsorQuery } from '../../types/graphql'

import { Container } from '../Container'
import { Section } from '../Section'
import { SponsorList } from '../SponsorList'

import * as classes from './footer.module.scss'

import LogoPR from '../../images/logo-footer.svg'

export const Footer = () => {
  const data = useStaticQuery<SponsorQuery>(graphql`
    fragment SponsorChildImageSharp on File {
      childImageSharp {
        fluid(maxWidth: 256, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    fragment SponsorChildImageSharpSmall on File {
      childImageSharp {
        fluid(maxWidth: 128, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    fragment SponsorQuery on wordpress__wp_sponsor {
      id
      slug
      sponsorInfo {
        Sito_web
      }
      featured_media {
        localFile {
          ...SponsorChildImageSharp
        }
      }
      featured_media_wh {
        localFile {
          ...SponsorChildImageSharp
        }
      }
    }

    fragment SponsorQuerySmall on wordpress__wp_sponsor {
      id
      slug
      sponsorInfo {
        Sito_web
      }
      featured_media {
        localFile {
          ...SponsorChildImageSharpSmall
        }
      }
      featured_media_wh {
        localFile {
          ...SponsorChildImageSharpSmall
        }
      }
    }

    query Sponsor {
      basketPool: allWordpressWpSponsor(
        filter: { categoria_sponsor: { in: [10] } }
        sort: { fields: [slug], order: ASC }
      ) {
        nodes {
          ...SponsorQuerySmall
        }
      }

      institutionalPartners: allWordpressWpSponsor(
        filter: { categoria_sponsor: { in: [78] } }
      ) {
        nodes {
          ...SponsorQuery
        }
      }

      topSponsor: allWordpressWpSponsor(
        filter: { categoria_sponsor: { in: [60] } }
      ) {
        nodes {
          ...SponsorQuery
        }
      }

      coSponsor: allWordpressWpSponsor(
        filter: { categoria_sponsor: { in: [11] } }
      ) {
        nodes {
          ...SponsorQuery
        }
      }

      mainSponsor: allWordpressWpSponsor(
        filter: { categoria_sponsor: { in: [12] } }
      ) {
        nodes {
          ...SponsorQuery
        }
      }

      sponsor: allWordpressWpSponsor(
        filter: { categoria_sponsor: { in: [59] } }
        sort: { fields: [slug], order: ASC }
      ) {
        nodes {
          ...SponsorQuery
        }
      }

      fornitoriTecnici: allWordpressWpSponsor(
        filter: { categoria_sponsor: { in: [14] } }
      ) {
        nodes {
          ...SponsorQuery
        }
      }

      officialMedicalPartner: allWordpressWpSponsor(
        filter: { categoria_sponsor: { in: [67] } }
      ) {
        nodes {
          ...SponsorQuery
        }
      }

      coSponsorSettoreGiovanile: allWordpressWpSponsor(
        filter: { categoria_sponsor: { in: [73] } }
      ) {
        nodes {
          ...SponsorQuery
        }
      }

      privacyPolicy: wordpressWpMedia(slug: { eq: "privacy-policy-sito" }) {
        localFile {
          publicURL
        }
      }
    }
  `)

  return (
    <footer className={classes.footer}>
      <Container>
        <Section as={'div'} title={'Main Sponsor'} borderLess>
          <SponsorList
            imageClassName={classes.mainSponsorImage}
            sponsors={data.mainSponsor.nodes}
          />
        </Section>

        <Section title={'Top Sponsor'}>
          <SponsorList sponsors={data.topSponsor.nodes} />
        </Section>

        <Section title={'Co-Sponsor'}>
          <SponsorList sponsors={data.coSponsor.nodes} />
        </Section>

        <Section>
          <Row>
            <Col xs={12} md={6}>
              <Section as={'div'} title={'PARTNER ISTITUZIONALE'}>
                <SponsorList
                  sponsors={data.institutionalPartners.nodes}
                  width={6}
                  offset={0}
                />
              </Section>
            </Col>

            <Col xs={12} md={6}>
              <Section as={'div'} title={'OFFICIAL MEDICAL PARTNER'}>
                <SponsorList
                  sponsors={data.officialMedicalPartner.nodes}
                  width={6}
                  offset={0}
                />
              </Section>
            </Col>
          </Row>
        </Section>

        <Section title={'Basket Pool'}>
          <SponsorList sponsors={data.basketPool.nodes} />
        </Section>

        <Section>
          <Row>
            <Col xs={12} md={6}>
              <Section as={'div'} title={'CO-SPONSOR SETTORE GIOVANILE'}>
                <SponsorList
                  sponsors={data.coSponsorSettoreGiovanile.nodes}
                  width={6}
                  offset={0}
                />
              </Section>
            </Col>

            <Col xs={12} md={6}>
              <Section as={'div'} title={'Fornitore Tecnico'}>
                <SponsorList
                  sponsors={data.fornitoriTecnici.nodes}
                  width={6}
                  offset={0}
                />
              </Section>
            </Col>
          </Row>
        </Section>

        <Section>
          <Row>
            <Col xs={12} sm={8} md={8}>
              <img
                src={LogoPR}
                alt={'Logo pallacanestro reggiana'}
                className={classes.footerLogo}
              />
              <p>
                PALLACANESTRO REGGIANA SRL
                <br />
                Via Martiri della Bettola, 47 - 42123 Reggio Emilia
                <br />
                P.IVA 00532610359
                <br />
                Telefono:{' '}
                <a href={'callto:+390522287950'} className={classes.link}>
                  +39 0522 287950
                </a>
                <br />
                E-mail:{' '}
                <a
                  href={'mailto:reggiana@pallacanestroreggiana.it'}
                  className={classes.link}
                >
                  reggiana@pallacanestroreggiana.it
                </a>
              </p>
              <a
                href={data.privacyPolicy.localFile.publicURL}
                className={classes.link}
                target={'_blank'}
                rel={'noreferrer noopener'}
              >
                Privacy Policy
              </a>{' '}
              |{' '}
              <Link to={'/cookie-policy'} className={classes.link}>
                Cookie Policy
              </Link>
              |{' '}
              <Link to={'/whistleblowing'} className={classes.link}>
                Whistleblowing
              </Link>
              |{' '}
              <Link to={'/safeguarding-policy'} className={classes.link}>
                Safeguarding Policy
              </Link>
            </Col>
            <Col xs={12} sm={4} md={4}>
              <p className={classes.credits}>
                Project & design{' '}
                <a
                  href={'https://ventie30.it'}
                  target='_blank'
                  className={classes.link}
                >
                  Ventie30
                </a>
              </p>
            </Col>
          </Row>
        </Section>
      </Container>
    </footer>
  )
}
