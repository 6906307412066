// extracted by mini-css-extract-plugin
export var active = "navbar-module--active--ee5mz";
export var bottom = "navbar-module--bottom--AhKKK";
export var bottomSearchIcon = "navbar-module--bottomSearchIcon--PHzFc";
export var brand = "navbar-module--brand--ADbwM";
export var collapse = "navbar-module--collapse--NPdJw";
export var container = "navbar-module--container--vmYjL";
export var logo = "navbar-module--logo--G8dXB";
export var middle = "navbar-module--middle--sW51Z navbar-module--sticky--9UUdP";
export var mobileBottomLinks = "navbar-module--mobileBottomLinks--PVaKG";
export var nav = "navbar-module--nav--9CmFy";
export var navLink = "navbar-module--navLink--6Lre4";
export var socials = "navbar-module--socials--nryki";
export var sticky = "navbar-module--sticky--9UUdP";
export var toggle = "navbar-module--toggle--v3N12";
export var top = "navbar-module--top--l0Cne navbar-module--sticky--9UUdP";
export var topSearchIcon = "navbar-module--topSearchIcon--3ZYBw";