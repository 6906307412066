import React from 'react'
import GatsbyImage from 'gatsby-image'

import * as classes from './people.module.scss'

interface Props {
  className?: string
  featuredMedia?: {
    alt_text?: string
    localFile?: {
      childImageSharp?: {
        fluid?: any
      }
    }
  }
}

export const Image = ({ featuredMedia, ...props }: Props) => {
  if (!featuredMedia) {
    return null
  }

  return (
    <GatsbyImage
      fluid={featuredMedia?.localFile?.childImageSharp?.fluid}
      alt={featuredMedia?.alt_text!}
      {...props}
    />
  )
}
