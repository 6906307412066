import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { StaffQuery } from '../../types/graphql'

import { People } from '../People'

interface Props {
  filter?: string
}

export const Staff = ({ filter }: Props) => {
  const { staff } = useStaticQuery<StaffQuery>(graphql`
    query Staff {
      staff: allWordpressWpStaff(
        filter: { squadra: { eq: 104 } }
        sort: { fields: [info___Ordinamento], order: ASC }
      ) {
        nodes {
          id
          info {
            Ruolo
          }
          featuredMedia: featured_media {
            alt_text
            localFile {
              ...PersonImage
            }
          }
          slug
          title
        }
      }
    }
  `)

  return <People filter={filter} nodes={staff.nodes} />
}
