import React from 'react'
import { Row, Col } from 'react-bootstrap'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'

import * as classes from './sponsor-list.module.scss'

import { SponsorQueryFragment } from '../../types/graphql'

const getSponsorLink = (info: SponsorQueryFragment['sponsorInfo']) => {
  if (info?.Attachment?.localFile) {
    return info.Attachment.localFile.publicURL!
  } else if (info?.Sito_web) {
    return info.Sito_web!
  }
}

interface SponsorListProps {
  imageClassName?: ClassValue
  offset?: number
  sponsors: ReadonlyArray<SponsorQueryFragment>
  width?: number
}

const SCALE_FACTOR = 12

export const SponsorList = ({
  imageClassName,
  sponsors,
  offset = 5,
  width = 12,
}: SponsorListProps) => (
  <Row>
    {sponsors.map((node, index, self) => (
      <Col
        xs={{
          span: Math.min(6 * Math.floor(SCALE_FACTOR / width), 6),
          offset: self.length === 1 ? 3 : 0,
        }}
        md={{
          span: 2 * Math.floor(SCALE_FACTOR / width),
          offset: self.length === 1 ? offset : 0,
        }}
        key={node.id}
      >
        <div className={classes.sponsor}>
          <div className={classes.content}>
            <a
              href={getSponsorLink(node.sponsorInfo)}
              target={'_blank'}
              rel={'noreferrer noopener'}
            >
              {node.featured_media_wh && (
                <GatsbyImage
                  fluid={
                    node.featured_media_wh?.localFile?.childImageSharp
                      ?.fluid as FluidObject
                  }
                  className={classNames(
                    classes.image,
                    classes.white,
                    imageClassName
                  )}
                />
              )}

              {node.featured_media && (
                <GatsbyImage
                  fluid={
                    node.featured_media?.localFile?.childImageSharp
                      ?.fluid as FluidObject
                  }
                  className={classNames(
                    classes.image,
                    classes.color,
                    imageClassName
                  )}
                />
              )}
            </a>
          </div>
        </div>
      </Col>
    ))}
  </Row>
)
