import React from 'react'
import classNames from 'classnames'

import * as classes from './image.module.scss'

interface Props extends React.HTMLAttributes<HTMLImageElement> {
  src: string
  alt: string
}

export const Image = ({ className, ...props }: Props) => (
  <img className={classNames(classes.image, className)} {...props} />
)
