import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Nav } from 'react-bootstrap'

import * as classes from './navbar.module.scss'

interface SearchIconProps {
  className: string
  click: () => void
}

export const SearchIcon = ({ className, click }: SearchIconProps) => (
  <Nav.Link
    className={classNames(classes.navLink, className)}
    aria-label={'Search Button'}
  >
    <FontAwesomeIcon icon={faSearch} onClick={click} />
  </Nav.Link>
)
