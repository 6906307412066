// extracted by mini-css-extract-plugin
export var date = "news-grid-module--date--K4WQF";
export var detailsIcon = "news-grid-module--detailsIcon--UTwqR";
export var hover = "news-grid-module--hover--jTvhS";
export var hover1 = "news-grid-module--hover1--L8BMi";
export var hover2 = "news-grid-module--hover-2--Cpl9C";
export var hover3 = "news-grid-module--hover-3--O49P7";
export var hover4 = "news-grid-module--hover-4--TDLqL";
export var image = "news-grid-module--image--tAHW4";
export var linkSection = "news-grid-module--linkSection--T-gwE";
export var thumb = "news-grid-module--thumb--If90d";
export var windowScrollerOverride = "news-grid-module--window-scroller-override--AMGGn";