import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

interface Props {
  prefix?: string
  slug?: string
}

export const Link: React.FC<Props> = ({ prefix, slug, children }) => {
  if (!prefix || !slug) {
    return <div>{children}</div>
  }

  return <GatsbyLink to={`${prefix}/${slug}`}>{children}</GatsbyLink>
}
