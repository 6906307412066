import React from 'react'
import { Row, Col } from 'react-bootstrap'
import classNames from 'classnames'

import { PersonFragment } from '../../types/graphql'

import { Image } from './Image'
import { Link } from './Link'

import * as classes from './people.module.scss'

interface Props {
  filter?: string
  nodes: PersonFragment[]
  urlPrefix?: string
}

const getFilteredNodes = (nodes: PersonFragment[], filter?: string) => {
  if (filter === undefined) {
    return nodes
  }

  if (filter.length === 0) {
    return []
  }

  return nodes.filter((node) =>
    node.title!.toLowerCase().includes(filter.toLowerCase())
  )
}

export const People = ({ filter, nodes, urlPrefix }: Props) => (
  <Row>
    {getFilteredNodes(nodes, filter).map((node) => (
      <Col xs={12} sm={6} md={3} className={classes.person} key={node.id}>
        <Link prefix={urlPrefix} slug={node.slug!}>
          <div className={classes.relative}>
            <Image
              featuredMedia={node.featuredMedia}
              className={classNames(!!node.featuredMediaHover && classes.image)}
            />

            <Image
              featuredMedia={node.featuredMediaHover}
              className={classes.hoverImage}
            />

            <div className={classes.container}>
              <h5 className={classes.title}>{node.title}</h5>
            </div>
          </div>

          <div className={classes.role}>
            <p className={classes.text}>{node.info?.Ruolo}</p>
          </div>
        </Link>
      </Col>
    ))}
  </Row>
)
