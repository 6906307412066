import React from 'react'
import { Form, FormControl, FormText } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import InfiniteScroll from 'react-infinite-scroller'
import { WindowLocation } from '@reach/router'
import { WindowScroller } from 'react-virtualized'
import { connect } from 'react-redux'

import { AppState } from '../../reducers'
import { ApiArticle } from '../../actions/articles'
import { Article } from '../../reducers/articles'
import { getAllArticles } from '../../selectors/articles'

import { Container } from '../Container'
import { FirstTeam } from '../FirstTeam'
import { NewsGrid } from '../NewsGrid'
import { OrganizationChart } from '../OrganizationChart'
import { Staff } from '../Staff'

import { api } from '../../utils/api'
import { transformApiArticles } from '../../utils/articles'

import * as classes from './search.module.scss'

interface SearchProps {
  show: boolean
  close: () => void
  location: WindowLocation
  articles: ReturnType<typeof getAllArticles>
}

interface SearchState {
  fetching: boolean
  error: boolean
  data: Article[]
  hasMoreContents: boolean
  search: string
}

class _Search extends React.Component<SearchProps, SearchState> {
  state = {
    fetching: false,
    error: false,
    data: [],
    hasMoreContents: true,
    search: '',
  }

  newsFeedWindowScrollerRef = React.createRef<WindowScroller>()

  componentDidUpdate(
    prevProps: Readonly<SearchProps>,
    prevState: Readonly<SearchState>,
    snapshot?: any
  ): void {
    const { location, close, show } = this.props

    if (prevProps.location.pathname !== location.pathname) {
      close()
    }

    if (show) {
      const inputElement = document.querySelector<HTMLInputElement>(
        `.${classes.input}`
      )

      inputElement?.focus()
    }
  }

  componentWillUnmount(): void {
    const { show, close } = this.props

    if (show) {
      close()
    }
  }

  getScrollerElement = () => {
    return document.querySelector<HTMLElement>(`.${classes.search}`)
  }

  handleSearchChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    this.setState((prevState) => ({
      ...prevState,
      search: value,
      data: value === prevState.search ? prevState.data : [],
      hasMoreContent: value !== prevState.search,
    }))

    const scrollerElement = this.getScrollerElement()

    if (this.newsFeedWindowScrollerRef.current && scrollerElement) {
      this.newsFeedWindowScrollerRef.current.updatePosition(scrollerElement)
    }
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    this.fetchContents()
  }

  fetchContents = async () => {
    const { search, data } = this.state

    try {
      this.setState({ fetching: true })

      const contents = await api.get<ApiArticle[]>(
        `/posts?search=${search}&offset=${data.length}`
      )

      this.setState((prevState) => ({
        data: [...prevState.data, ...contents.map(transformApiArticles)],
        hasMoreContents: contents.length > 0,
      }))
    } catch {
      this.setState({ error: true })
    } finally {
      this.setState({ fetching: false })
    }
  }

  render() {
    const { show, close } = this.props
    const { search, data, fetching, error, hasMoreContents } = this.state
    const userHasSearched = !(hasMoreContents && data.length === 0)

    if (!show) {
      return null
    }

    return (
      <div className={classes.search}>
        <InfiniteScroll
          loadMore={this.fetchContents}
          useWindow={false}
          hasMore={!fetching && !error && hasMoreContents && userHasSearched}
        >
          <Container className={classes.container}>
            <div className={classes.alignRight}>
              <FontAwesomeIcon
                className={classes.closeIcon}
                icon={faTimes}
                onClick={close}
              />
            </div>

            <Form onSubmit={this.onSubmit} className={classes.form}>
              <FormControl
                onChange={this.handleSearchChange}
                placeholder={'Ricerca'}
                className={classes.input}
                value={search}
              />
              <FormText className={classes.formText}>
                Premi invio per cercare
              </FormText>
            </Form>

            {userHasSearched && (
              <>
                <OrganizationChart filter={search} />
                <FirstTeam filter={search} />
                <Staff filter={search} />
              </>
            )}

            <NewsGrid
              category={'tutte-le-news'}
              padding={8}
              contents={data}
              className={classes.grid}
              windowScrollerElement={this.getScrollerElement()}
              windowScrollerRef={this.newsFeedWindowScrollerRef}
            />

            {!fetching && !hasMoreContents && data.length === 0 && (
              <p>Non sono presenti articoli</p>
            )}

            {fetching && <p>Caricamento...</p>}

            {!hasMoreContents && data.length > 0 && (
              <p>Hai visto tutti gli articoli</p>
            )}
          </Container>
        </InfiniteScroll>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  articles: getAllArticles(state),
})

export const Search = connect(mapStateToProps)(_Search)
