import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { OrganigrammaQuery } from '../../types/graphql'

import { People } from '../People'

interface Props {
  filter?: string
}

export const OrganizationChart = ({ filter }: Props) => {
  const { organizationChart } = useStaticQuery<OrganigrammaQuery>(graphql`
    query Organigramma {
      organizationChart: allWordpressWpOrganigramma(
        sort: { fields: [info___Ordinamento], order: ASC }
      ) {
        nodes {
          id
          info {
            Ruolo
          }
          featuredMedia: featured_media {
            alt_text
            localFile {
              ...PersonImage
            }
          }
          slug
          title
        }
      }
    }
  `)

  return <People filter={filter} nodes={organizationChart.nodes} />
}
