import React, { useCallback, useRef } from 'react'
import { WindowScroller, AutoSizer } from 'react-virtualized'
import { FixedSizeGrid, GridProps } from 'react-window'

import { CellRenderer } from './CellRenderer'

import { Article } from '../../reducers/articles'

import * as classes from './news-grid.module.scss'

interface NewsGridProps extends React.HTMLAttributes<HTMLDivElement> {
  category: string
  padding: number
  path?: string
  contents: Article[]
  onItemsRendered?: GridProps['onItemsRendered']
  windowScrollerElement?: any
  windowScrollerRef?: React.RefObject<WindowScroller>
}

const getColumnCount = (width: number) => {
  if (width <= 576) {
    return 1
  } else if (width > 576 && width <= 768) {
    return 2
  } else {
    return 3
  }
}

export const NewsGrid: React.FC<NewsGridProps> = ({
  category,
  className,
  padding,
  contents,
  windowScrollerElement,
  windowScrollerRef,
  onItemsRendered,
}) => {
  const fixedSizeGridRef = useRef<FixedSizeGrid>(null)

  const getFixedSizeGridProps = useCallback(
    (width: number) => {
      const columnCount = getColumnCount(width)

      return {
        columnWidth: width / columnCount,
        columnCount,
        rowCount: Math.ceil(contents.length / columnCount),
        rowHeight: ((width / columnCount) * 9) / 16 + 200,
      }
    },
    [contents]
  )

  const handleScroll = useCallback(
    (params: { scrollTop: number; scrollLeft: number }) => {
      if (fixedSizeGridRef.current) {
        fixedSizeGridRef.current.scrollTo(params)
      }
    },
    []
  )

  if (windowScrollerElement === null) {
    return null
  }

  return (
    <div className={className}>
      <WindowScroller
        ref={windowScrollerRef}
        scrollElement={windowScrollerElement}
        onScroll={handleScroll}
      >
        {({ isScrolling }) => (
          <div style={{ margin: `0 -${padding}px` }}>
            <AutoSizer defaultWidth={375} disableHeight>
              {({ width }) => {
                const fixedSizeGridProps = getFixedSizeGridProps(width)

                return (
                  <FixedSizeGrid
                    ref={fixedSizeGridRef}
                    itemData={contents}
                    {...fixedSizeGridProps}
                    height={
                      typeof window !== 'undefined' ? window.innerHeight : 812
                    }
                    width={width}
                    className={classes.windowScrollerOverride}
                    onItemsRendered={onItemsRendered}
                  >
                    {(props) => (
                      <CellRenderer
                        category={category}
                        columnCount={fixedSizeGridProps.columnCount}
                        padding={padding}
                        {...props}
                        isScrolling={isScrolling}
                      />
                    )}
                  </FixedSizeGrid>
                )
              }}
            </AutoSizer>
          </div>
        )}
      </WindowScroller>
    </div>
  )
}
