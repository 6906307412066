import React from 'react'
import classNames from 'classnames'

import { ComponentProps } from '../../types/component'

import { Container } from '../Container'
import { Media, DataType } from '../Media'
import { Title } from '../Title'

import * as classes from './section.module.scss'

interface WrapperProps {
  withContainer: boolean
}

const Wrapper: React.FC<WrapperProps> = ({
  children,
  withContainer = false,
}) => {
  if (withContainer) {
    return <Container>{children}</Container>
  }

  return <>{children}</>
}

export interface SectionProps
  extends React.HTMLAttributes<HTMLDivElement>,
    ComponentProps {
  borderLess?: boolean
  title?: string
  withContainer?: boolean
  titleClassName?: string
  cover?: DataType
}

export const Section = ({
  as: Component = 'section',
  title,
  titleClassName,
  children,
  className,
  withContainer = false,
  borderLess = false,
  cover,
  ...props
}: SectionProps) => (
  <Component className={classNames(classes.section, className)} {...props}>
    <Wrapper withContainer={withContainer}>
      <Media data={cover} imageClassName={classes.cover} />

      {title && (
        <div
          className={classNames(
            classes.titleContainer,
            borderLess && classes.borderLess,
            titleClassName
          )}
        >
          <Title as={'h5'} className={classes.title}>
            {title}
          </Title>
        </div>
      )}

      {children}
    </Wrapper>
  </Component>
)
