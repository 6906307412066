import React from 'react'
import classNames from 'classnames'

import { ComponentProps } from '../../types/component'

import * as classes from './title.module.scss'

export interface TitleProps
  extends ComponentProps,
    React.HTMLAttributes<HTMLHeadingElement> {
  centered?: boolean
}

export const Title: React.FC<TitleProps> = ({
  as: Component = 'h1',
  centered = false,
  className,
  ...props
}) => (
  <Component
    className={classNames(
      classes.title,
      centered && classes.centered,
      className
    )}
    {...props}
  />
)
