import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import moment from 'moment'
import 'moment/locale/it'
import classNames from 'classnames'
import { GridChildComponentProps } from 'react-window'

import { Article } from '../../reducers/articles'

import iconPost from '../../images/post-details-icon.svg'

import * as classes from './news-grid.module.scss'

moment.locale('it')

interface CellRendererProps extends GridChildComponentProps {
  category: string
  columnCount: number
  data: Article[]
  padding: number
}

export const CellRenderer = React.memo(
  ({
    category,
    columnCount,
    columnIndex,
    rowIndex,
    style,
    data,
    padding,
    isScrolling,
  }: CellRendererProps) => {
    const index = columnCount * rowIndex + columnIndex

    if (index > data.length - 1) {
      return null
    }

    const article = data[index]

    const featuredImage = isScrolling
      ? article?.featuredImage?.thumbnail
      : article?.featuredImage?.full

    return (
      <div style={{ padding: `0 ${padding}px`, ...style }} key={article.id}>
        <Link
          to={`/news/${category}/${article.slug}`}
          className={classes.linkSection}
        >
          <div
            className={classes.image}
            style={{
              backgroundImage: `url(${featuredImage})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <img
              className={classes.detailsIcon}
              src={iconPost}
              alt={'Icona post'}
            />
          </div>

          <p className={classNames(classes.date)}>
            {moment(article.date).format('D MMMM YYYY')}
          </p>

          <h6
            className={classNames(classes.title, classes.hover, classes.hover1)}
          >
            {parse(article.title)}
          </h6>
        </Link>
      </div>
    )
  }
)
