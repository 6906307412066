import React from 'react'
import GatsbyImage, { FluidObject } from 'gatsby-image'

import { Image } from '../Image'

export type DataType = string | FluidObject

interface BaseProps {
  data?: DataType
  imageClassName?: string
  alt?: string
}

interface MediaComponentProps extends BaseProps {
  data: DataType
}

const MediaComponent = ({
  data,
  imageClassName,
  ...props
}: MediaComponentProps) => {
  switch (typeof data) {
    case 'string':
      return <Image className={imageClassName} src={data} {...props} />
    case 'object':
      return <GatsbyImage className={imageClassName} fluid={data} {...props} />
    default:
      return null
  }
}

type Props = BaseProps & React.HTMLAttributes<HTMLDivElement>

export const Media: React.FC<Props> = ({
  data,
  className,
  imageClassName,
  children,
  alt,
}) => {
  if (!data) {
    return null
  }

  return (
    <div className={className}>
      <MediaComponent imageClassName={imageClassName} data={data} alt={alt} />

      {children}
    </div>
  )
}
