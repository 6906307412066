import React from 'react'
import {
  Container as BootstrapContainer,
  ContainerProps as BootstrapContainerProps,
} from 'react-bootstrap'
import classNames from 'classnames'

import * as classes from './container.module.scss'

export interface ContainerProps
  extends BootstrapContainerProps,
    React.HTMLAttributes<HTMLDivElement> {
  small?: boolean
  medium?: boolean
  fullWidth?: boolean
  size?: 'small' | 'medium' | 'large'
}

export const Container = ({
  className,
  small = false,
  medium = false,
  fullWidth = false,
  size,
  ...props
}: ContainerProps) => (
  <BootstrapContainer
    className={classNames(
      classes.container,
      size && classes[size],
      fullWidth && classes.fullWidth,
      className
    )}
    {...props}
  />
)
